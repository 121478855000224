
import { computed, defineComponent, onMounted, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';

import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonInput,
  IonItem, IonLabel, IonButton, IonIcon, loadingController,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonDatetimeButton, } from '@ionic/vue';
  import { trashOutline, cloudDoneOutline } from 'ionicons/icons';

import moment from 'moment-timezone';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import PageDescription from '@/components/PageDescription.vue';
import { useErrorBox } from '@/components/errorBox';

import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { useAuthStore } from '@/store/authStore';
import { useConfigStore } from '@/store/configStore';
import { useNotificationStore } from '@/store/notificationStore';

import { LanguageDto } from '@/models/configService/Languages';
import { EMessageRecipientType, getMessageRecipientStatusName, messageApprovalOptions, messageRecipientTypeOptions, RecipientTransactionsDto, TransactionalMessageContentDto, TransactionalMessageDto } from '@/models/notificationService/Transactional';

import { stringToHash } from '@/utils/changeTracking';
import { useMerchantStore } from '@/store/merchantStore';
import { MerchantSimpleDto } from '@/models/merchantService/Merchant';

export default defineComponent({
  name: 'TransactionalMessageDetailsPage',
  components: {
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    IonGrid, IonRow, IonCol, 
    IonDatetime,
    IonDatetimeButton,
    IonInput, 
    IonItem, 
    IonIcon,
    IonLabel,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonModal,
    PageDescription,
    AdminMenu,
  },
  setup (){
    const auth = useAuthStore();
    const configStore = useConfigStore();
    const notificationStore = useNotificationStore();
    const merchantStore = useMerchantStore();
        
    const router = useRouter();
    const loaded = ref(false); 
    const allLanguages:Ref<Array<LanguageDto>> = ref([]);
    const newLanguage:Ref<LanguageDto|undefined> = ref(undefined);
    const currentMessage:Ref<TransactionalMessageDto|undefined> = ref(undefined);
    const recipients:Ref<Array<RecipientTransactionsDto>> = ref([]);
    const merchants: Ref<Array<MerchantSimpleDto>> = ref([]);

    const newMerchantsById: Ref<string> = ref("");
    const newUsersByEmail: Ref<string> = ref("");
    const newMerchantSearchByName: Ref<string> = ref("");
    const newMerchantSearchResult: Ref<MerchantSimpleDto|undefined> = ref(undefined);

    const editor = ClassicEditor;
    const editorConfig = {};

    const { showError } = useErrorBox();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
        const loading = await loadingController.create({
          cssClass: "my-custom-class",
          message: "Processing...",
          duration: undefined,
        });

        await loading.present();
        return loading;
      };

      
    onMounted(async () => {
      if(!auth.filteredRoles.includes("Admins"))
        return;
      
      const l = await loading();
      try {
        const id = router.currentRoute.value.params["id"].toString();
        
        if(id != null && id != "" && id != "create") {
          currentMessage.value = await notificationStore.getTransactionalMessage(id);
          recipients.value = await notificationStore.getTransactionalMessageRecipients(id);
          merchants.value = await merchantStore.getAllMerchantsSimpleList();
        } else {
          currentMessage.value = new TransactionalMessageDto();
          currentMessage.value.message = [];
          addLocale("en");
        }

        allLanguages.value = await configStore.getLanguages();

      } catch (error) {
        await showError(error, "Error loading retrun reasons details");
      } finally {
        await l.dismiss();
      }

      loaded.value = true;
      savedHash.value = hashComponentState.value;

    });

    const save = async () => {
      if(!currentMessage.value)
        return;

      const l = await loading();
      try {

        if(currentMessage.value?.uid == "null" || currentMessage.value?.uid == "") {
          currentMessage.value = await notificationStore.createTransactionalMessage(currentMessage.value);
          router.replace({name: 'TransactionalMessageDetails', params: {id: currentMessage.value.uid}});
        } else {
          currentMessage.value = await notificationStore.updateTransactionalMessage(currentMessage.value);
        }

        savedHash.value = hashComponentState.value;
      } catch (error) {
        await showError(error, "Error saving return reasons details");
      } finally {
        await l.dismiss();
      }      
    };

    const removeLanguage = async (lang:TransactionalMessageContentDto) => {
      if(currentMessage.value && currentMessage.value.message)
        currentMessage.value.message = currentMessage.value.message.filter(i => i.locale != lang.locale);        
    };

    const addLocale = async (lang:string) => {
      console.log(lang);
      if(currentMessage.value && currentMessage.value.message) {
        if(currentMessage.value.message.find(i => i.locale == lang))
          return;
        currentMessage.value.message.push({locale: lang, title: "", body: ""});
      }
    };

    const translateEmpty = async () => {
      if(!currentMessage.value)
        return;
      const l = await loading();
      try {

        // update titles
        const enTitle = currentMessage.value.message.find(i=>i.locale == "en")?.title;
        const missingTitleLocales = currentMessage.value.message
          .filter(i=>i.locale != "en" && !i.title)
          .map(i => i.locale )
          .join(",");
        
        if(enTitle && missingTitleLocales) {
          (await configStore.translate(enTitle, "en", missingTitleLocales))
            .forEach(element => {
              const empty = currentMessage.value!.message.find(i=>i.locale == element.language);
              if(empty && !empty.title && element.text) empty.title = element.text;
            });
        }

        // update bodies
        const enBody = currentMessage.value.message.find(i=>i.locale == "en")?.body;
        const missingBodyLocales = currentMessage.value.message
          .filter(i=>i.locale != "en" && !i.body)
          .map(i => i.locale )
          .join(",");

        if(enBody && missingBodyLocales) {          
          (await configStore.translate(enBody, "en", missingBodyLocales))
          .forEach(element => {
            const empty = currentMessage.value!.message.find(i=>i.locale == element.language);
            if(empty && !empty.body && element.text) empty.body = element.text;
          });
        }
    
      } catch (error) {
        await showError(error, "Error translating text");
      } finally {
        await l.dismiss();
      }
    }

    const addRecipientMerchants = async () => {
      if(!currentMessage.value || !newMerchantsById.value)
        return;

      const l = await loading();
      try {
        const ids = newMerchantsById.value.split(/[\s,;]+/);
        for (let index = 0; index < ids.length; index++) {
          const element = ids[index];
          if(element == "") continue;
          await notificationStore.addTransactionalMessageRecipient(currentMessage.value.uid, element);
        }
        recipients.value = await notificationStore.getTransactionalMessageRecipients(currentMessage.value.uid);
        newMerchantsById.value = "";
      } catch (error) {
        await showError(error, "Error adding recipients");
      } finally {
        await l.dismiss();
      }
    }

    const addRecipientUsers = async () => {
      if(!currentMessage.value || !newUsersByEmail.value)
        return;

      const l = await loading();
      try {
        const emails = newUsersByEmail.value.split(/[\s,;]+/);
        for (let index = 0; index < emails.length; index++) {
          const element = emails[index];
          if(element == "") continue;
          await notificationStore.addTransactionalMessageRecipient(currentMessage.value.uid, element);
        }
        recipients.value = await notificationStore.getTransactionalMessageRecipients(currentMessage.value.uid);
        newUsersByEmail.value = "";
      } catch (error) {
        await showError(error, "Error adding recipients");
      } finally {
        await l.dismiss();
      }
    }

    const searchForMerchant = async () => {
      if(!newMerchantSearchByName.value || newMerchantSearchByName.value.length < 2) {
        newMerchantSearchResult.value = undefined;
        return;
      }

      const searchString = newMerchantSearchByName.value.toLocaleLowerCase();
      newMerchantSearchResult.value = merchants.value.find(i=>i.companyName && i.companyName.toLocaleLowerCase().indexOf(searchString) >= 0);
    }

    const addRecipientMerchantsSearchResult = async () => {
      if(!currentMessage.value || !newMerchantSearchResult.value)
        return;

      const l = await loading();
      try {
        await notificationStore.addTransactionalMessageRecipient(currentMessage.value.uid, newMerchantSearchResult.value.uid);
        recipients.value = await notificationStore.getTransactionalMessageRecipients(currentMessage.value.uid);
        newMerchantSearchResult.value = undefined;
      } catch (error) {
        await showError(error, "Error adding recipients");
      } finally {
        await l.dismiss();
      }
    }


    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {

      return stringToHash(JSON.stringify({
        currentMessage: currentMessage.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    const getForIonDateTime = (date:Date|string|undefined) => {
      return momentjs(date).utc(true).toISOString();
    }

    const setFromIonDateTime = (date:any) => {
      return momentjs(date).toISOString();
    }

    const getMerchantName = (uid:string) => {
      return merchants.value.find(i=>i.uid == uid)?.companyName ?? uid;
    }

    return {
      isDirty, currentMessage, recipients, addLocale,
      cloudDoneOutline, trashOutline, newLanguage,
      editor, editorConfig,
      loaded, allLanguages, save,
      getForIonDateTime, setFromIonDateTime, momentjs, getMerchantName,
      removeLanguage, translateEmpty,
      messageRecipientTypeOptions, messageApprovalOptions,
      EMessageRecipientType, getMessageRecipientStatusName,
      newMerchantsById, newUsersByEmail, addRecipientMerchants, addRecipientUsers,
      newMerchantSearchByName, newMerchantSearchResult, addRecipientMerchantsSearchResult, searchForMerchant,
    }

}
});

