

/// <summary>
/// Who should see this message
/// </summary>
export enum EMessageRecipientType {
    /// <summary>
    /// Users are not selected when creating message.
    /// New recipient will be added if not in list when shown.
    /// Single user is recipient of this message
    /// </summary>
    AllUsers = 0,

    /// <summary>
    /// Merchants are not selected when creating message.
    /// New recipient will be added if not in list when shown.
    /// Single merchant is recipient of this message.
    /// Users of merchants are saved under same recipient.
    /// </summary>
    AllMerchants = 1,

    /// <summary>
    /// Recipients are selected when creating message.
    /// Will only be visible to users listed already.
    /// </summary>
    SpecifiedUsers = 2,

    /// <summary>
    /// Recipients are selected when creating message.
    /// Will only be visible to merchants listed already.
    /// All users of merchant will be saved to same recipient.
    /// </summary>
    SpecifiedMerchants = 3,

    /// <summary>
    /// Users having specific roles.
    /// Single user will have own recipient and status.
    /// </summary>
    UsersOfSpecifiedRoles = 4,
}

export const getMessageRecipientTypeName = (type: EMessageRecipientType) => {
    switch(type){
      case EMessageRecipientType.AllUsers:
        return "All Users";
      case EMessageRecipientType.AllMerchants:
        return "All Merchants";
      case EMessageRecipientType.SpecifiedUsers:
        return "Specified Users";
      case EMessageRecipientType.SpecifiedMerchants:
        return "Specified Merchants";
      case EMessageRecipientType.UsersOfSpecifiedRoles:
        return "Users Of Specified Roles";
      default:
        return "Unknown";
    }
}

export const messageRecipientTypeOptions = [
  { id: EMessageRecipientType.AllUsers, title: "All Users" },
  { id: EMessageRecipientType.AllMerchants, title: "All Merchants" },
  { id: EMessageRecipientType.SpecifiedUsers, title: "Specified Users" },
  { id: EMessageRecipientType.SpecifiedMerchants, title: "Specified Merchants" },
  { id: EMessageRecipientType.UsersOfSpecifiedRoles, title: "Users Of Specified Roles" },
];

/// <summary>
/// Approval type of message
/// </summary>
export enum EMessageApproval {
    /// <summary>
    /// Show only. No need to approve.
    /// </summary>
    ShowOnly = 0,

    /// <summary>
    /// Recipient must approve message
    /// </summary>    
    ApprovalRequired = 1,
}



export const getMessageApprovalTypeName = (type: EMessageApproval) => {
    switch(type){
      case EMessageApproval.ShowOnly:
        return "-";
      case EMessageApproval.ApprovalRequired:
        return "Required";
      default:
        return "Unknown";
    }
}

export const messageApprovalOptions = [
  { id: EMessageApproval.ShowOnly, title: "-" },
  { id: EMessageApproval.ApprovalRequired, title: "Required" },
];

export class TransactionalMessageContentDto {
    locale: string;
    title: string;
    body: string;

    constructor() {
        this.locale = "";
        this.title = "";
        this.body = "";
    }
}

export class TransactionalMessageDto {
    uid: string;
    message: Array<TransactionalMessageContentDto>;
    recipientType: EMessageRecipientType;
    approvalType: EMessageApproval;
    visibleFrom: string|Date;
    approvalDeadline?: string|Date;
    visibleTo: string|Date;
    internalTitle: string;
    deleted: boolean;

    constructor() {
        this.uid = "";
        this.message = [];
        this.recipientType = EMessageRecipientType.AllMerchants;
        this.approvalType = EMessageApproval.ShowOnly;
        this.visibleFrom = new Date().toISOString();
        this.visibleTo = new Date().toISOString();
        this.internalTitle = "";
        this.deleted = false;
    }
}

export enum EMessageRecipientStatus {
		Draft = 0,
		Sent = 1,
		Read = 2,
		Skipped = 3,
		Agreed = 4,
		Rejected = 5,
}

export const getMessageRecipientStatusName = (type: EMessageRecipientStatus) => {
    switch(type){
      case EMessageRecipientStatus.Draft:
        return "Draft";
      case EMessageRecipientStatus.Sent:
        return "Sent";
      case EMessageRecipientStatus.Read:
        return "Read";
      case EMessageRecipientStatus.Skipped:
        return "Skipped";
      case EMessageRecipientStatus.Agreed:
        return "Agreed";
      case EMessageRecipientStatus.Rejected:
        return "Rejected";
      default:
        return "Unknown";
    }
}

export class RecipientTransactionsDto {
  uid: string;
  messageUid: string;
  recipient: string;
  status: EMessageRecipientStatus;
  transactions: Array<TransactionDto>;

  constructor() {
    this.uid = "";
    this.messageUid = "";
    this.recipient = "";
    this.status = EMessageRecipientStatus.Sent;
    this.transactions = [];
  }
}

export class TransactionDto {
  time: string | Date;
  status: EMessageRecipientStatus;
  userName: string;
  service: string;
  source: string;

  constructor() {
    this.time = new Date().toISOString();
    this.status = EMessageRecipientStatus.Sent;
    this.userName = "";
    this.service = "";
    this.source = "";
  }
}